// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-page p,
.project-page h2,
.project-page h1,
.project-page h3 {
  width: var(--max-width);
  margin: auto;
}

.project-page p.description {
  padding-bottom: 10vmin !important;
  padding-top: 3vmin !important;
}

.project-page .title {
  padding-top: 20vmin;
}

.project-page p {
  max-width: 700px;
  padding-top: 10vmin;
  padding-bottom: 1rem;
  margin: auto;
}

.project-page p + p {
  padding-top: 0;
  padding-bottom: 10vmin;
}

/* //images */
.project-page .full-width {
  background-color: var(--surface);
  margin: 3rem 2rem 0;
}

.project-page .full-width img {
  width: 100%;
}

@media (min-width: 700px) {
  .project-page {
    text-align: center;
  }
  .project-page p {
    max-width: 500px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/project-page.css"],"names":[],"mappings":"AAAA;;;;EAIE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA,aAAa;AACb;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".project-page p,\r\n.project-page h2,\r\n.project-page h1,\r\n.project-page h3 {\r\n  width: var(--max-width);\r\n  margin: auto;\r\n}\r\n\r\n.project-page p.description {\r\n  padding-bottom: 10vmin !important;\r\n  padding-top: 3vmin !important;\r\n}\r\n\r\n.project-page .title {\r\n  padding-top: 20vmin;\r\n}\r\n\r\n.project-page p {\r\n  max-width: 700px;\r\n  padding-top: 10vmin;\r\n  padding-bottom: 1rem;\r\n  margin: auto;\r\n}\r\n\r\n.project-page p + p {\r\n  padding-top: 0;\r\n  padding-bottom: 10vmin;\r\n}\r\n\r\n/* //images */\r\n.project-page .full-width {\r\n  background-color: var(--surface);\r\n  margin: 3rem 2rem 0;\r\n}\r\n\r\n.project-page .full-width img {\r\n  width: 100%;\r\n}\r\n\r\n@media (min-width: 700px) {\r\n  .project-page {\r\n    text-align: center;\r\n  }\r\n  .project-page p {\r\n    max-width: 500px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
