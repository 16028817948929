// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "IBM Plex Mono", monospace;
}

h1 {
  font-size: 2.027rem;
  padding-top: 10vmin;
}

h2 {
  font-size: 1.802rem;
  padding-top: 5vmin;
  padding-bottom: 2vmin;
}

h3 {
  font-size: 1.602rem;
  padding-top: 2.5vmin;
  padding-bottom: 1vmin;
}

h4 {
  font-size: 1.424rem;
}

h5 {
  font-size: 1.266rem;
}

h6 {
  font-size: 1.125rem;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
}

small {
  font-size: 0.889rem;
}
`, "",{"version":3,"sources":["webpack://./src/css/text-style.css"],"names":[],"mappings":"AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap\");\r\n\r\n* {\r\n  font-family: \"IBM Plex Mono\", monospace;\r\n}\r\n\r\nh1 {\r\n  font-size: 2.027rem;\r\n  padding-top: 10vmin;\r\n}\r\n\r\nh2 {\r\n  font-size: 1.802rem;\r\n  padding-top: 5vmin;\r\n  padding-bottom: 2vmin;\r\n}\r\n\r\nh3 {\r\n  font-size: 1.602rem;\r\n  padding-top: 2.5vmin;\r\n  padding-bottom: 1vmin;\r\n}\r\n\r\nh4 {\r\n  font-size: 1.424rem;\r\n}\r\n\r\nh5 {\r\n  font-size: 1.266rem;\r\n}\r\n\r\nh6 {\r\n  font-size: 1.125rem;\r\n}\r\n\r\np {\r\n  font-size: 1rem;\r\n  line-height: 1.6;\r\n  font-weight: 500;\r\n}\r\n\r\nsmall {\r\n  font-size: 0.889rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
