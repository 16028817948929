import { create } from "../dom.js";
import createNav from "../navigation.js";
import logoSrc from "../../assets/text-logo.svg";

// Generates full page header
export default function header() {
  const header = create("header");

  const wrapper = create("div", ["header-wrapper", "max-width"], null, header);

  wrapper.append(title(), headerNav("pages"));

  return header;
}

// for header: clickable logo
function title() {
  const title = create("h1");

  create("span", "sr-only", "Olusade", title);

  const link = create("a", null, null, title);
  link.href = "./";
  link.ariaLabel = "Home";

  const logo = create("img", "logo", null, link);
  logo.src = logoSrc;

  return title;
}

// for header: main navigation (navbar/sidebar)
function headerNav() {
  const nav = createNav("pages", "max-width");

  // bar with hamburger icon showed on smaller screens
  const navBar = create("div", ["nav-bar", "max-width"], null, nav);

  const navOverlay = create("div", "nav-overlay", null, nav);

  nav.querySelectorAll("*").forEach((el) => {
    el.addEventListener("click", handleNavBarClick);
  });

  // don't want evenlistener on icon
  // double click opens and closes nav bar
  const navBarIcon = create("i", "material-symbols-outlined", "menu", navBar);

  /// -----------------------------------------------------

  return nav;

  function handleNavBarClick(e) {
    nav.classList.toggle("open");

    toggleTextContent(navBarIcon, "menu", "close");
  }
}

function toggleTextContent(element, txt1, txt2) {
  if (element.textContent == txt1) {
    element.textContent = txt2;
  } else {
    element.textContent = txt1;
  }
}
