const projects = [
  {
    name: "Spell Clash: Eldoria",
    id: "spell-clash",
    link: "https://melsowole.github.io/fe23-js1-mp2-melinda-sowole/",
    year: "November 2023",
    projectLength: "2 weeks",
    role: "Programmer & Designer",
    webTechnologies: "Javscript & Bootstrap",
    thumbnail:
      "https://i.ibb.co/1MzDjZ9/spell-clash-thumbnail.png",
    description: "A game of rock-paper-scissors dressed up as a fantasy RPG.",
    descriptionLong: "Spell Clash: Eldoria is a game of rock-paper-scissors dressed as a fantasy RPG where the player has to defeat the evil wizard Morgathar to save Eldoria from corruption.",
    body: [
      {
        src: "https://i.ibb.co/n3ND2gD/spell-clash-gui.png",
        alt: "Spell Clash GUI on desktop and mobile"
      },
      {
        src: "https://i.ibb.co/cwQ0N4G/spell-clash-morgathar.png",
        alt: "Morgathar design",
      },
      "As a school assignment, the main focus of the project was implementing the various passing criteria, such as: create a functional rock-paper-scissors mechanic played against a computer, and enter player name before the game starts.",
      "Additionally, I wanted to create a deeper game-experince, implementing typical game features such as character creation and animations.",
      {
        src:"https://i.ibb.co/XyMpCZN/spell-clash-customization.png",
        alt: "Character Customization"
      }
    ]
  },
  {
    name: "Anthme",
    id: "anthme",
    link: "https://github.com/andjan3/FE23-js2-slutprojekt-anthme",
    year: "March 2024",
    projectLength: "2 weeks",
    role: "Programmer",
    webTechnologies: "Node & Typescript",
    thumbnail:
      "https://i.ibb.co/xh5QxVC/anthme-thumbnail.png",
    description: "A not-so-subtle Reddit knock-off.",
    descriptionLong: "Anthme is a platform designed for users to share and engage in discussions across various topics, drawing not-so-subtle inspiration from Reddit.",
    body: [
      {
        src: "https://i.ibb.co/jGD1nDq/anthme-home.png",
        alt: "Anthme Home page"
      },
      "The project was built in collaboration with 2 other developers. The backend and frontend are written in Typescript with Express.js for the server.",
      "I primarily contributed by writing the base structure for the backend and wrote the code for the counfational layout of the frontend application, including designing the header, sidebars, and main content on the home page.",
      {
        src:"https://i.ibb.co/dKFJTP8/anthme-interactions.gif",
        alt: "Anthme interactions with sidebar and upvote-downvote features"
      }
    ]
  },


  // {
  //   name: "WYD",
  //   id: "wyd",
  //   link: "",
  //   year: "January 2024",
  //   projectLength: "2 weeks",
  //   role: "Project Leader & Programmer",
  //   webTechnologies: "Javscript & Firebase",
  //   thumbnail:
  //     "https://previews.dropbox.com/p/thumb/ACNLhNPi9hp9VTEwFtyiy5p4XHPInoWLLzhMmNoCajGz6ahzjRBTtzsuo61eXxC3klzE9f80JMu63QU9kPFIVl5uLmN-MWxfo5s7ZFotQZLfb8OpK3Eu6JnbRM6efmT0CubvvyceGlHyS7mBDxaYUsfBvsQCm8zw1MQ4eic3AkcV0I_Cn_jHhyVg2cd9BJcBWVwxHZWHWqOu1vNuNGm88OJO8oHN1F80wsn3XRSbsYgSHupMiuBoepVlhDRYGp60GLL_zoyKF79uAi9EHTspfUw3aeEeG5AX4c-O0sSFP85PG34dWuZnjBICKcTiTuuY-ipoivgr7xcZJ9cWfZ_FllUU/p.png",
  //   description: "A web application for sharing your thoughts with the world.",
  //   descriptionLong:
  //     "WYD is a web application where users can share their thoughts and read what others worldwyd are up to.",
  // },
];

export default projects;
