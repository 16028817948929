import createNav from "../navigation.js";
import { create } from "../dom.js";
import logoSrc from "../../assets/icon-logo.svg";

export default function createFooter() {
  const footer = create("footer", "page-footer");

  const divider = create("div", "divider", null, footer);

  const logo = create("img", "logo", null, divider);
  logo.src = logoSrc;

  const navSection = create("section", null, null, divider);
  navSection.appendAll(
    create("h3", "", "Navigation", navSection),
    createNav("pages")
  );

  const socialsSection = create("section", "external-link", null, divider);
  socialsSection.appendAll(
    create("h3", "", "Socials", navSection),
    createNav("socials")
  );

  const credit = create(
    "small",
    ["page-credit"],
    "© 2024 Olusade. All rights reserved.",
    footer
  );

  return footer;
}
