import { create } from "../dom.js";
import projects from "../projects-info.js";

// Generates full page header
export default async function projectPage(projectId, container) {
  const project = projects.find((p) => p.id == projectId);

  const projectPage = create("section", ["project-page"], "", container);

  create("h2", "title", project.name, projectPage);
  create("p", "description", project.descriptionLong, projectPage);

  projectPage.append(createBody(project.body), viewDeployment(project.link))

  return projectPage;
}

function createBody(contentArray){
  const wrapper = create("div", "project-body");

  contentArray.forEach(content => {
    if(content.src){
      wrapper.append(createImg(content))
    } else {
      wrapper.append(create("p", "", content))
    }
  });

  return wrapper;
}

function createImg({src, alt}){
  const wrapper = create("div", "full-width");

  const img = create("img");
  img.src = src;
  img.alt = alt;

  wrapper.append(img);
  return wrapper;
}


function viewDeployment(link) {
  const el = create("p");

  el.innerHTML = `
    Thanks for reading about this project! To experience it, <a href="${link}" target="_blank">view the project on Github</a>.
    <br>
    <br>
    To see my other projects, <a href="/">click here</a>
  `;

  return el;
}
