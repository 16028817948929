import { create } from "../dom.js";

const form = `
<form action="https://formspree.io/f/xqkrqwaq" method="POST">
  <div class="label-input-wrapper">
    <label for="email"><small>E-mail</small></label>
    <input type="email" id="email" name="email" placeholder="Your Email Address" required>
  </div>

  <div class="label-input-wrapper">
    <label for="name"><small>Name</small></label>
    <input type="text" id="name" name="name" placeholder="Your Name" required>
  </div>

  <div class="label-input-wrapper">
    <label for="subject"><small>Subject</small></label>
      <input type="text" id="subject" name="subject" placeholder="Subject of Your Message" required>
  </div>

  <div class="label-input-wrapper">
    <label for="message"><small>Message</small></label>
    <textarea name="message" id="message" class="txt-s" placeholder="Your message here"></textarea>
  </div>

    <!-- your other form fields go here -->
    <button type="submit">Send</button>
  </form>
`;

export default function contact() {
  const section = create("section", "contact-section");
  section.id = "contact";

  const wrapper = create("div", ["max-width", "contact-wrapper"], 0, section);

  create("h2", "section-title", "Contact", wrapper);
  create(
    "p",
    "paragraph",
    "Find me on Github @melsowole or send me an email via the form!",
    wrapper
  );

  wrapper.innerHTML += form;
    

  return section;
}
