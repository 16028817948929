export function create(tagName, classes, content, parent) {
  const element = document.createElement(tagName);

  if (classes) addClasses(element, classes);

  if (content) {
    if (typeof content == "string" || content == "number") {
      element.innerText = content;
    } else if (content instanceof HTMLElement) {
      element.append(content);
    } else if (content instanceof Array) {
      content.forEach((string) => {
        const p = document.createElement("p");
        p.textContent = string;
        element.append(p);
      });
    } else {
      throw "From create(): WHAT IS THIS: " + content;
    }
  }

  if (parent) parent.append(element);

  return element;
}

function addClasses(elem, classes) {
  if (typeof classes == "string") classes = [classes];

  for (const cls of classes) {
    elem.classList.add(cls);
  }
}
