// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
  text-align: center;
  background-color: var(--primary-container);
  color: var(--on-primary-container);
}

.contact-section * {
  color: inherit;
}

.contact-section .contact-wrapper {
  max-width: 500px;
  margin: auto;
}

.contact-section .paragraph {
  padding-bottom: 1rem;
}

.contact-section form {
  display: grid;
  gap: 1rem;
}

.label-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.label-input-wrapper label {
  padding-bottom: 0.25rem;
}

.label-input-wrapper label::after {
  content: "*";
  position: relative;
  left: 0.25rem;
  font-size: 0.6rem;
  bottom: 0.5rem;
}

.label-input-wrapper input,
.label-input-wrapper textarea {
  border: 1px solid var(--on-primary-container);
  width: 100%;
  background-color: transparent;
  padding: 0.5rem;
}

.contact-section button {
  background-color: var(--on-primary-container);
  color: var(--primary-container);
  padding: 0.5rem 2rem;
  cursor: pointer;
  border: none;
}

@media (min-width: 700px) {
  .contact-section .contact-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/contact.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,kCAAkC;AACpC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,cAAc;AAChB;;AAEA;;EAEE,6CAA6C;EAC7C,WAAW;EACX,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,6CAA6C;EAC7C,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,YAAY;AACd;;AAEA;EACE;IACE,8BAA8B;IAC9B,SAAS;EACX;AACF","sourcesContent":[".contact-section {\r\n  text-align: center;\r\n  background-color: var(--primary-container);\r\n  color: var(--on-primary-container);\r\n}\r\n\r\n.contact-section * {\r\n  color: inherit;\r\n}\r\n\r\n.contact-section .contact-wrapper {\r\n  max-width: 500px;\r\n  margin: auto;\r\n}\r\n\r\n.contact-section .paragraph {\r\n  padding-bottom: 1rem;\r\n}\r\n\r\n.contact-section form {\r\n  display: grid;\r\n  gap: 1rem;\r\n}\r\n\r\n.label-input-wrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: start;\r\n}\r\n\r\n.label-input-wrapper label {\r\n  padding-bottom: 0.25rem;\r\n}\r\n\r\n.label-input-wrapper label::after {\r\n  content: \"*\";\r\n  position: relative;\r\n  left: 0.25rem;\r\n  font-size: 0.6rem;\r\n  bottom: 0.5rem;\r\n}\r\n\r\n.label-input-wrapper input,\r\n.label-input-wrapper textarea {\r\n  border: 1px solid var(--on-primary-container);\r\n  width: 100%;\r\n  background-color: transparent;\r\n  padding: 0.5rem;\r\n}\r\n\r\n.contact-section button {\r\n  background-color: var(--on-primary-container);\r\n  color: var(--primary-container);\r\n  padding: 0.5rem 2rem;\r\n  cursor: pointer;\r\n  border: none;\r\n}\r\n\r\n@media (min-width: 700px) {\r\n  .contact-section .contact-wrapper {\r\n    grid-template-columns: 1fr 1fr;\r\n    gap: 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
