// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-footer {
  text-align: center;
  background-color: var(--background);
  border-top: 1px solid var(--on-background);
}

/* override style of dark reader extension */
.page-footer *,
.page-footer img.logo,
.page-footer ul a {
  background-color: var(--background);
  color: var(--on-background);
}

.page-footer .divider {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding-top: 3rem;
  padding-bottom: 5rem;
}

.page-footer .logo {
  width: 35px;
}

.page-footer section {
  width: 100%;
}

.page-footer h3 {
  padding-top: 0;
}

.page-footer nav ul {
  list-style-type: none;
}

.page-footer nav ul a {
  padding: 1rem 0;
  display: block;
}

.page-credit {
  padding: 1rem;
}

@media (min-width: 700px) {
  .page-footer .divider {
    text-align: left;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    max-width: fit-content;
    margin: auto;

    padding-top: 5rem;
    padding-bottom: 4rem;
  }

  .page-footer .divider section {
    width: auto;
    padding: 0rem 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,0CAA0C;AAC5C;;AAEA,4CAA4C;AAC5C;;;EAGE,mCAAmC;EACnC,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;;EAET,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;;IAEZ,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;AACF","sourcesContent":[".page-footer {\r\n  text-align: center;\r\n  background-color: var(--background);\r\n  border-top: 1px solid var(--on-background);\r\n}\r\n\r\n/* override style of dark reader extension */\r\n.page-footer *,\r\n.page-footer img.logo,\r\n.page-footer ul a {\r\n  background-color: var(--background);\r\n  color: var(--on-background);\r\n}\r\n\r\n.page-footer .divider {\r\n  padding: 1rem;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 2rem;\r\n\r\n  padding-top: 3rem;\r\n  padding-bottom: 5rem;\r\n}\r\n\r\n.page-footer .logo {\r\n  width: 35px;\r\n}\r\n\r\n.page-footer section {\r\n  width: 100%;\r\n}\r\n\r\n.page-footer h3 {\r\n  padding-top: 0;\r\n}\r\n\r\n.page-footer nav ul {\r\n  list-style-type: none;\r\n}\r\n\r\n.page-footer nav ul a {\r\n  padding: 1rem 0;\r\n  display: block;\r\n}\r\n\r\n.page-credit {\r\n  padding: 1rem;\r\n}\r\n\r\n@media (min-width: 700px) {\r\n  .page-footer .divider {\r\n    text-align: left;\r\n    flex-direction: row;\r\n    align-items: start;\r\n    justify-content: center;\r\n    max-width: fit-content;\r\n    margin: auto;\r\n\r\n    padding-top: 5rem;\r\n    padding-bottom: 4rem;\r\n  }\r\n\r\n  .page-footer .divider section {\r\n    width: auto;\r\n    padding: 0rem 2rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
