// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-section {
  min-height: 85svh;
}

.projects-wrapper {
  display: grid;
  gap: 5rem 3rem;
}

.project .title {
  grid-area: title;
}

.project .thumbnail {
  width: 100%;
  grid-area: img;
  background-color: vaR(--surface);
  aspect-ratio: 4 / 2;
  display: grid;
  place-items: center;
}

.project .thumbnail img {
  width: 100%;
}

.project .links {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1000px) {
  .projects-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/project-section.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gCAAgC;EAChC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE;IACE,aAAa;IACb,8BAA8B;EAChC;AACF","sourcesContent":[".project-section {\r\n  min-height: 85svh;\r\n}\r\n\r\n.projects-wrapper {\r\n  display: grid;\r\n  gap: 5rem 3rem;\r\n}\r\n\r\n.project .title {\r\n  grid-area: title;\r\n}\r\n\r\n.project .thumbnail {\r\n  width: 100%;\r\n  grid-area: img;\r\n  background-color: vaR(--surface);\r\n  aspect-ratio: 4 / 2;\r\n  display: grid;\r\n  place-items: center;\r\n}\r\n\r\n.project .thumbnail img {\r\n  width: 100%;\r\n}\r\n\r\n.project .links {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n@media (min-width: 1000px) {\r\n  .projects-wrapper {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
