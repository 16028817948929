import { create } from "./dom.js";

const lists = {
  pages: [
    { text: "Home", dir: "./" },
    { text: "Projects", dir: "./#projects" },
    // { text: "About", dir: "#about" },
    { text: "Contact", dir: "./#contact" },
  ],
  socials: [
    {
      text: "Linkedin",
      dir: "https://www.linkedin.com/in/melinda-s-609ba7206/",
    },
    { text: "Github", dir: "#" },
  ],
};

export default function createNavList(type) {
  const nav = create("nav");

  const navList = create("ul", null, null, nav);

  lists[type].forEach((item) => {
    const li = create("li", null, null, navList);

    if (window.location.pathname == item.dir) {
      li.classList.add("active");
    }

    const link = create("a", "link", item.text, li);
    if (type == "socials") {
      link.classList.add("external");
    }
    link.href = item.dir;
  });

  return nav;
}
