import "./css/style.css";
import footer from "./modules/page-sections/footer.js";
import header from "./modules/page-sections/header.js";
import projects from "./modules/projects-info.js";
import projectSection from "./modules/page-sections/project-section.ts";
import projectPage from "./modules/page-sections/project-page.js";
import contactSection from "./modules/page-sections/contact.js";
import icon from "./assets/icon-logo.svg";

setFavicon();

HTMLElement.prototype.appendAll = function (...elements) {
  if (Array.isArray(elements[0])) {
    elements = elements[0];
  }
  elements.forEach((el) => {
    this.append(el);
  });
};

document.body.prepend(header());
document.body.append(contactSection());
document.body.append(footer());

const main = document.querySelector("main");

const urlParams = new URLSearchParams(window.location.search);
let project = false;
for (const projectName of urlParams.keys()) {
  project = projectName;
}

if (project) {
  projectPage(project, main);
} else {
  main.append(projectSection());
}

function setFavicon(){
  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = icon; 

  document.head.appendChild(link);
}